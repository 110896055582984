import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { Drawer } from 'antd'
import { useNavigate, useLocation } from 'react-router-dom'
import { Text, Box, Image } from '@/packages'
import { logo, xWhite, discordWhite, github, close, menu } from '@/assets/images'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { Button, Row, Col, ContentWrapper } from '@/components'

const NavWrapper = styled(Box)`
  height: 120px;
`
const list = [
  {
    url: 'https://x.com/the1aiagent',
    icon: xWhite
  }
  // {
  //   url: 'https://discord.com/invite/predx-949853427771535370',
  //   icon: discordWhite
  // },
  // {
  //   url: 'https://github.com/Cointegrator',
  //   icon: github
  // }
]
const IconWrapper = styled(Box)`
  height: 40px;
  width: 40px;
  border: 1px solid #ffffff1a;
  border-radius: 8px;
  &:hover {
    border: 1px solid #fff;
  }
`

export const LaunchNav = () => {
  const [isOpen, setOpen] = useState<boolean>(false)
  const { isMobile, isTablet } = useMatchBreakpoints()
  const navigate = useNavigate()
  const NavTabs = [
    { url: '/about', name: 'ABOUT' },
    { url: '/product', name: 'PRODUCT' }
  ]
  const location = useLocation()

  const [height, setHeight] = useState<number>(0)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = document.documentElement.scrollTop || document.body.scrollTop
      setHeight(scrollY)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <ContentWrapper>
      <Box className={height > 64 ? 'scroll_nav ' : 'nav'} height="120px">
        <Box className="click fx-row ai-ct" onClick={() => navigate('/')}>
          <Image src={logo} className="w100 h100" alt="" height={66} width={66} />
          <Text className="white fw500" fontSize={['18px', '18px', '22px', '22px']}>
            The 1
          </Text>
        </Box>
        {list.map((item) => (
            <IconWrapper className="center click ml8" onClick={() => window.open(item.url, '_blank')}>
              <Image src={item.icon} height={16} width={16} />
            </IconWrapper>
          ))}
      </Box>
    </ContentWrapper>
  )
}
