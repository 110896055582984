import React, { useState, useEffect } from 'react'
import { styled } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import Typed from 'typed.js'
import { ContentWrapper, PageTitle, CardWrapper, GreenWrapper, LineTextLine, Footer } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import { logo } from '@/assets/images/'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'

const Wrapper = styled(Box)`
  position: absolute;
  left: 50%;
  bottom: 140px;
  transform: translate(-50%, 50%);
`
const LogoWrapper = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`
const SLOGAN_LIST = [
  'Complex Interaction Analysis',
  'Dynamic Graph Transformer',
  'Generative Replay Learning',
  'Hierarchical Dependencies'
]

export const Dashboard = () => {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [index, setIndex] = useState<number>(0)
  const el = React.useRef(null)
  React.useEffect(() => {
    const typed = new Typed(el.current, {
      strings: [
        'Complex Interaction Analysis',
        'Dynamic Graph Transformer',
        'Generative Replay Learning',
        'Hierarchical Dependencies'
      ],
      typeSpeed: 100,
      loop: true,
      loopCount: Infinity
    })

    return () => {
      // Destroy Typed instance during cleanup to stop animation
      typed.destroy()
    }
  }, [])

  return (
    <Box>
      <LogoWrapper className="center">
        <Box className="fx-col ai-ct ">
          <Image
            src={logo}
            height={isMobile || isTablet ? 200 : 300}
            width={isMobile || isTablet ? 200 : 300}
            // style={{ marginTop: isMobile || isTablet ? '-90%' : '-20%' }}
            style={{ marginTop: '-200px' }}
          />

          <Box height="24px" className="" >
            <Text
              ref={el}
              className="white"
              fontSize={['18px', '18px', '24px', '24px']}
              // style={{ display: 'inline-block' }}
            />
          </Box>
        </Box>
      </LogoWrapper>
      <Wrapper className=" w100">
        <ContentWrapper>
          <Grid 
            gridTemplateColumns={['1fr', '1fr', '1fr 1fr', '1fr 1fr']} 
            className="w100"
            gridGap={['0px','0px','12px','12px']}
            mt={['120px','24px','0px','0px']}
          >
            <Box>
              <Text className=" fw500 white" fontSize={['24px', '24px', '26px', '26px']}>
                Omniscient and Omnipotent Intelligence
              </Text>
              <Text 
                className="gray mt24" 
                fontSize={['16px', '16px', '18px', '18px']}
               
              >
                "The 1" is a multilayer graph transformer architecture that processes heterogeneous dynamic networks
                using generative replay learning. It leverages hierarchical graph neural networks to capture both
                structural patterns and temporal dependencies across different types of entities. The system analyzes
                complex multi-agent interactions by integrating social, blockchain, and external data sources through
                contextually-aware simulations.
              </Text>
            </Box>
            <Box className=" fx-col jc-end" marginTop={['24px', '24px', '0', '0']}>
              <LineTextLine name="The 1" />
              <Text 
                className="gray mt24" 
                fontSize={['14px', '14px', '16px', '16px']}
              >
                CA: BhmEQeiFd8Cu5fB6nmxm9rUsQj9Pu35X96RKv6GKtRJU
              </Text>
              <CardWrapper
                className="center click mt12"
                bg="#fff"
                hover="rgb(203,203,203)"
                height="45px"
                onClick={() => navigate('/about')}
              >
                <Text className="black " fontSize={['14px', '14px', '16px', '16px']}>
                  What is "The 1" ?
                </Text>
              </CardWrapper>
              <Grid className="mt12" gridTemplateColumns="2fr 1fr">
                <CardWrapper
                  className="center click"
                  height="45px"
                  // onClick={() => window.open('https://events.predx.ai', '_blank')}
                >
                  <Text className="white " fontSize={['14px', '14px', '16px', '16px']}>
                    Agent (coming soon)
                  </Text>
                </CardWrapper>
                <CardWrapper className="center ml12 click" height="45px" onClick={() => navigate('/product')}>
                  <Text className="white" fontSize={['14px', '14px', '16px', '16px']}>
                    Technology
                  </Text>
                </CardWrapper>
              </Grid>
            </Box>
          </Grid>
        </ContentWrapper>
      </Wrapper>
      {/* <Footer /> */}
    </Box>
  )
}
