import React, { useState } from 'react'
import { styled } from 'styled-components'
import { ContentWrapper, PageTitle, CardWrapper, Footer } from '@/components'
import { Box, Text, Grid} from '@/packages'
import { useMatchBreakpoints } from '@/hooks/useMatchBreakpoints'
import { PDF } from "@/assets/images"
import pdf1 from '../../assets/pdf/a1.pdf'; 
import pdf2 from '../../assets/pdf/a2.pdf'; 
import pdf3 from '../../assets/pdf/a3.pdf'; 
import pdf4 from '../../assets/pdf/b1.pdf'; 
import pdf5 from '../../assets/pdf/b2.pdf'; 
import pdf6 from '../../assets/pdf/b3.pdf'; 
import pdf7 from '../../assets/pdf/c1.pdf'; 
import pdf8 from '../../assets/pdf/c2.pdf'; 
import pdf9 from '../../assets/pdf/c3.pdf'; 
import tech1 from '../../assets/images/a1.png';
import tech2 from '../../assets/images/a2.png';
import tech3 from '../../assets/images/a3.png';
import tech4 from '../../assets/images/b1.png';
import tech5 from '../../assets/images/b2.png';
import tech6 from '../../assets/images/b3.png';
import tech7 from '../../assets/images/c1.png';
import tech8 from '../../assets/images/c2.png';
import tech9 from '../../assets/images/c3.png';

const list = [
  // style="color: #ad2218; "
  {
    title1: 'About',
    title2: 'Dynamic Graph Transformer',
    desc1: `
      <p>The Dynamic Graph Transformer (DGT) serves as a foundational element of "The1". 
      This model is specifically designed to process evolving graph structures by utilizing 
      multiple token types to capture diverse node and edge features.</p>
      
      <p>The DGT effectively learns graph topology and captures implicit links, which is crucial 
      for understanding complex interactions within dynamic graphs (Wu et al., 2024).</p>

      <p>By employing time-windowed subgraphs, the DGT efficiently tracks temporal changes, allowing 
      the model to adapt dynamically to shifting relationships over time. This approach is supported 
      by recent advancements that highlight the importance of incorporating spatial-temporal encoding 
      to maintain the integrity of both structural and temporal information (Biparva et al., 2023).</p>

      <p>For instance, the introduction of Supra-Laplacian encoding enables the DGT to leverage spectral 
      properties of multi-layer graphs, thereby enhancing its ability to capture dependencies between 
      nodes across multiple time steps (Zhang et al., 2022).</p>

      <p>Furthermore, the inductive learning framework allows the DGT to generalize across unseen graph 
      structures without requiring full retraining. This scalability is particularly beneficial for 
      real-time applications where data continuously evolves. The DGT's capacity to maintain performance 
      amidst changing data landscapes positions "The1" as a leading solution in dynamic environments.</p>

      <p><b>References:</b></p>
    
    `,
    ref1: '1. Wu, Y., Fang, Y., Tian & Liao, L. (2024). On the Feasibility of Simple Transformer for Dynamic Graph Modeling. Proceedings of the ACM Web Conference.',
    ref2: '2. Biparva, M., Karimi, R., Faez, F., & Zhang, Y. (2023). Todyformer: Towards Holistic Dynamic Graph Transformers with Structure-Aware Tokenization. Transactions on Machine Learning Research.',
    ref3: '3. Zhang, Y., & Li, J. (2022). Supra-Laplacian Encoding for Transformer on Dynamic Graphs. arXiv preprint.',
    img1: tech1,
    img2: tech2,
    img3: tech3,
    pdf1: pdf1,
    pdf2: pdf2,
    pdf3: pdf3,
   
  },
  {
    title1: 'About',
    title2: 'Generative Replay Learning',
    desc1: `
      <p>To further enhance its capabilities, "The1" integrates Generative Replay Learning (GRL), 
      which is crucial for maintaining and updating knowledge while preventing catastrophic forgetting—a significant challenge in machine learning models. 
      By employing historical simulations and pattern matching techniques, GRL ensures that "The1" continuously learns from both past and present data.</p>

      <p>Recent studies have demonstrated the effectiveness of GRL in dynamic environments. For example, 
      Gao et al. (2023) introduced a deep diffusion-based generative replay method that enhances traditional GR frameworks 
      by allowing the generator to synthesize samples based on instructions from the classifier. This dual interaction not only 
      improves sample quality but also ensures that the classifier remains informed by past knowledge.</p>

      <p>By leveraging meta-path sampling techniques within GRL frameworks, "The1" aligns historical patterns with incoming data streams effectively. 
      This continuous learning mechanism significantly improves predictive accuracy across evolving graph environments and makes 
      "The1" robust against shifts in data distributions.</p>

      <p><b>References:</b></p>
    `,
    ref1: '1. Gao, X., & Zhang, Y. (2023). DDGR: Continual Learning with Deep Diffusion-based Generative Replay. Proceedings of the International Conference on Machine Learning.',
    ref2: '2. Maekawa, A., Kamigaito, H., Funakoshi, K., & Okumura, M. (2023). Generative Replay Inspired by Hippocampal Memory Indexing for Continual Language Learning. The Conference of the European Chapter of the Association for Computational Linguistics.',
    ref3: '3. Febrinanto, F.G., Xia, F., Moore K., Thapa C., & Aggarwal, C. (2023). Graph Lifelong Learning: A Survey. IEEE Computational Intelligence Magazine.',
    img1: tech4,
    img2: tech5,
    img3: tech6,
    pdf1: pdf4,
    pdf2: pdf5,
    pdf3: pdf6,
  },
  {
    title1: 'About',
    title2: 'Hierarchical Structural Dependencies',
    desc1: `
      <p>To effectively model Hierarchical Structural Dependencies, "The1" employs context-aware aggregation techniques 
      combined with meta-path-based attention mechanisms. This integration allows for capturing both local and global interactions 
      within large heterogeneous graphs efficiently.</p>

      <p>This method scales effectively and enables a nuanced understanding of complex relationships among various entities within the data structure. 
      By focusing on hierarchical attention mechanisms that model structural dependencies in dynamic graphs, "The1" can discern intricate dependencies that are 
      vital for accurate analysis (Song et al., 2023).
      .</p>

      <p>Moreover, this approach allows "The1" to adaptively capture dynamic relationships among nodes as they evolve over time—an 
      essential feature for applications in social networks and financial systems where interactions are constantly changing.</p>

      <p><b>References:</b></p>
    `,
    ref1: '1. Song, Z., & King, I. (2023). Hierarchical Heterogeneous Graph Attention Network for Syntax-Aware Summarization. Proceedings of the AAAI Conference on Artificial Intelligence.',
    ref2: '2. Qian, H., Zhou, H., ZHao, Q., Chen, Hao., Yao, H., Wang, J., Liu, Z., Yu, F., Zhang, Z. & Zhou, J. (2024). MDGNN: Multi-Relational Dynamic Graph Neural Network for Comprehensive and Dynamic Stock Investment Prediction. Proceedings of the AAAI Conference on Artificial Intelligence.',
    ref3: '3. Umejima, K., Nakamura, I., Fukui, N., Zushi, M., Narita, H., & Sakai, K.L. (2023). Differential networks for processing structural dependencies in human language: linguistic capacity vs. memory-based ordering. Frontiers in Psychology.',
    img1: tech7,
    img2: tech8,
    img3: tech9,
    pdf1: pdf7,
    pdf2: pdf8,
    pdf3: pdf9,
  }
]

export const Product = () => {
  const { isMobile, isTablet } = useMatchBreakpoints()
  const [activeIdx, setActiveIdx] = useState<number>(0)

  const onPrev = () => {
    setActiveIdx(activeIdx === 0 ? list.length - 1 : activeIdx - 1)
  }

  const onNext = () => {
    setActiveIdx(activeIdx === list.length - 1 ? 0 : activeIdx + 1)
  }

  return (
    <ContentWrapper>
      <PageTitle title="Method" subTitle="" />

      <Box className="" >
        {
          list.map(item => (
            <Box mt='48px'>
              <Box className="fx-row ai-ct" >
                <Text className="white " fontSize={['16px', '16px', '24px', '24px']}>
                  {item.title1}
                </Text>
                <Text className="main ml8" fontSize={['16px', '16px', '24px', '24px']}>
                  {item.title2}
                </Text>
              </Box>
              <Text
                className="white fw500"
                fontSize={['16px', '16px', '18px', '18px']}
                dangerouslySetInnerHTML={{ __html: item.desc1 }}
              />
              {
                [
                  {ref: item.ref1, img: item.img1, pdf: item.pdf1},
                  {ref: item.ref2, img: item.img2, pdf: item.pdf2},
                  {ref: item.ref3, img: item.img3, pdf: item.pdf3},
                ].map(refItem => (
                  <Grid 
                      gridTemplateColumns={['1fr', '3fr 1fr', '9fr 1.2f', '9fr 1.2fr']} 
                      mt="12px"
                      className='fx-row ai-ct jc-sb' 
                      key={refItem.ref}
                      borderRadius="16px"
                      padding="8px"
                      border="2px solid #ccc"
                    
                    >
                    <Box>
                      <Text 
                        className="white fw500"
                        fontSize={['16px', '16px', '18px', '18px']}
                      >{refItem.ref}</Text>
                      <Box className='click' mt="8px" mb="8px"  onClick={() => window.open(refItem.pdf, '_blank')}>
                        <img src={PDF} style={{ height: '24px', width: '24px', cursor: 'pointer'}} />
                      </Box>
                    </Box>
                    <img src={refItem.img} className="" style={{ width: isMobile ? '100%' : '120px',  }} />              
                  </Grid>
                 
                ))
              }

            </Box>
          ))
        }
       
      </Box>     
      <Footer />
    </ContentWrapper>
  )
}
