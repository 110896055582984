import React from 'react'
import { styled } from 'styled-components'
import hljs from 'highlight.js'
import { useNavigate } from 'react-router-dom'
import { ContentWrapper, PageTitle, GreenWrapper, Footer, CardWrapper, Col } from '@/components'
import { Box, Image, Text, Grid } from '@/packages'
import {
  doc1,
  doc2,
  doc3,
  docs,
  penrose,
  ark,
  mulana,
  onepiecelabs,
  sei,
  ceo,
  cto,
  cmo,
  cbo,
  coo
} from '@/assets/images/'

const list = [
  {
    title: 'Dynamic Graph Transformer',
    desc: 'The Multilayer Heterogeneous Dynamic Graph Transformer is designed to process evolving graph structures by using multiple token types to capture diverse node and edge features. It employs time-windowed subgraphs to efficiently track temporal changes in large, heterogeneous data structure, adapting to dynamic relationships over time.',
    icon: doc1
  },
  {
    title: 'Generative Replay Learning',
    desc: 'Generative Replay Learning uses historical simulation and pattern matching to maintain and update knowledge, preventing forgetting. By leveraging meta-path sampling, it aligns past patterns with new data, ensuring continuous learning and improving predictive accuracy across evolving graph environments.',
    icon: doc3
  },
  {
    title: 'Hierarchical Structural Dependencies',
    desc: 'To model hierarchical structural dependencies, the model integrates context-aware aggregation with meta-path-based attention, capturing both local and global interactions. This approach scales efficiently, allowing the model to understand complex, multi-layered relationships within large heterogeneous graphs.',
    icon: doc2
  }
]
export const About = () => {
  const navigate = useNavigate()

  React.useEffect(() => {
    document.querySelectorAll('pre').forEach((block) => {
      try {
        hljs.highlightBlock(block)
      } catch (e) {
        console.log(e)
      }
    })
  })

  return (
    <ContentWrapper>
      <PageTitle title="About" subTitle="What is The 1 ?" />
      <Text className="white mt48" fontSize={['18px', '18px', '24px', '24px']}>
        "The 1" representing the ultimate reality and source of all existence in Neoplatonism
      </Text>
      <Text className="gray mt8" fontSize={['14px', '14px', '16px', '16px']}>
        "The 1" is described as transcendent, ineffable, and beyond being itself. ”The 1" is a key concept in understanding
        the structure of reality, the hierarchy of being, and the spiritual practices aimed at achieving union with this
        divine source. "The 1" intelligence gives token holders the power and insightful knowledge from “The 1", the
        simplistic form, after all the mathematic complexity.
      </Text>

      <Text className="white mt48" fontSize={['18px', '18px', '24px', '24px']}>
        “The 1" is about complex relation learning
      </Text>
      <Text className="mt8 gray" fontSize={['14px', '14px', '16px', '16px']}>
        The1 surpasses traditional LLMs-based agents in solving complex reasoning problems like multi-asset relations by
        leveraging its graph-based architecture to model and analyze intricate, interconnected relationships. Its
        heterogeneous dynamic graph transformer allows for a contextualized, entity-level understanding of dependencies
        over time and across modalities, such as social, off-chain, and on-chain data. This enables The1 to capture
        latent structural patterns and temporal dynamics, far beyond the text-focused paradigm of LLMs. The use of
        generative replay learning ensures continual adaptation without catastrophic forgetting, while multi-agent
        simulation facilitates predictive reasoning in dynamic, multi-faceted environments, offering superior capability
        for asset relationship modeling.
      </Text>
      <Grid
        className="fx-row ai-ct mt12"
        gridGap={['0px', '0px', '24px', '24px']}
        gridTemplateColumns={['1fr', '1fr', '1fr 1fr 1fr', '1fr 1fr 1fr']}
      >
        <Text
          className="main fw500 click"
          fontSize={['16px', '16px', '20px', '20px']}
          onClick={() => navigate('/product')}
        >
          Explore more ↗
        </Text>
      </Grid>

      <Text className="white " marginTop={['24px', '24px', '48px', '48px']} fontSize={['18px', '18px', '24px', '24px']}>
        Surpassing GPT in predictive analytics
      </Text>
      <Grid
        className="fx-row ai-ct jc-sb w100 mt24"
        gridGap="24px" 
        style={{
          width: '100%' 
        }}
      >
        <Grid
          className="fx-row ai-start"
          gridGap="24px" // Consistent spacing between blocks
          gridTemplateColumns={['1fr','1fr','repeat(3, 1fr)','repeat(3, 1fr)']} // 3 columns for equal distribution
          style={{
            width: '100%' // Inner grid spans the full width of the parent
          }}
        >
          {list.map((item) => (
            <CardWrapper
              className="click pl24 pr24"
              bg="rgb(26,26,26)"
              hover="rgb(26,26,26)"
              style={{
                height: '400px', // Fixed height for uniformity
                display: 'flex',
                flexDirection: 'column', // Vertical arrangement
                justifyContent: 'flex-start', // Top-align content
                padding: '20px', // Internal padding
                textAlign: 'left', // Left-align text
                boxSizing: 'border-box', // Include padding in width calculations
                width: '100%' // Ensure full width in each grid column
              }}
            >
              <Col>
                <Text
                  className="white fw500 mb16"
                  fontSize={['20px', '20px', '24px', '24px']} // Title font size
                  style={{ wordWrap: 'break-word' }} // Ensure long text wraps properly
                >
                  {item.title}
                </Text>
                <Text
                  className="gray fw500"
                  fontSize={['14px', '14px', '16px', '16px']} // Description font size
                  style={{
                    wordWrap: 'break-word',
                    lineHeight: '1.5', // Improve readability
                    overflow: 'hidden', // Prevent content overflow
                    textOverflow: 'ellipsis' // Ellipsis for overflowed content
                  }}
                >
                  {item.desc}
                </Text>
              </Col>
            </CardWrapper>
          ))}
        </Grid>
      </Grid>

      <Text className="white mt48" fontSize={['18px', '18px', '24px', '24px']}>
        “The 1" core developers
      </Text>
      <Text className="mt8 gray" fontSize={['14px', '14px', '16px', '16px']}>
        The core AI developers of "The 1" are former researchers from the Visa Deep Authorization (VDA) project, 
        a state-of-the-art fraud detection model deployed globally. Leveraging deep learning and recurrent neural networks, 
        the VDA system processes petabytes of graph-structured transaction data to deliver advanced fraud prevention capabilities.
      </Text>
    
      <Footer />
    </ContentWrapper>
  )
}
